import React, { useState } from 'react';
import { Button, CircularProgress, Box, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { viewScaffoldingStyles } from './styles';

function ViewScaffolding({ form, link, action, label, description, resultMessage, errorMessage, classes }) {
    const [loading, setLoading] = useState(false);

    return (
        <Box width="100vw" height="100vh" bgcolor="#f6f5f6" display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column" width="275px" m="auto">
                <img className={classes.logoIcon} src={'/images/collums_logo.png'} alt="login-logo-icon" />
                <Box mt={1}>
                    <Typography className={classes.description}>{description}</Typography>
                </Box>
                <Box mb={1}>{form}</Box>
                {link}
                <Box mt={1}>
                    <Typography className={classes.result}>{resultMessage}</Typography>
                    <Typography className={classes.error}>{errorMessage}</Typography>
                </Box>
                <Box mt={2}>
                    <Box mb={2}>
                        <Button
                            className={classes.actionButton}
                            onClick={async (data) => {
                                setLoading(true);
                                await action(data);
                                setLoading(false);
                            }}
                            disabled={loading}
                        >
                            {loading ? '' : label}
                            {loading && <CircularProgress size={16} thickness={5} color="inherit" />}
                        </Button>
                    </Box>
                </Box>
                <Box mb={1} alignSelf="center">
                    <Typography className={classes.rightsText}>
                        ©{new Date().getFullYear()} Collums Solutions Ltd. All rights reserved
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

ViewScaffolding.propTypes = {
    form: PropTypes.any.isRequired,
    link: PropTypes.any.isRequired,
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    resultMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(viewScaffoldingStyles)(ViewScaffolding);
