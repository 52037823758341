export default {
    backendUrl: process.env.REACT_APP_BACKEND_URL || 'https://staging.api.collums.co.uk',
    authUrl: process.env.REACT_APP_AUTH_URL || 'https://staging.auth.collums.co.uk',
    calendarUrl: process.env.REACT_APP_CALENDAR_URL || 'https://staging.calendar.collums.co.uk',
    adminUrl: process.env.REACT_APP_ADMIN_URL || 'https://staging.admin.collums.co.uk',
    journeyUrl: process.env.REACT_APP_JOURNEY_URL || 'https://staging.journey.collums.co.uk',
    employeesUrl: process.env.REACT_APP_EMPLOYEES_URL || 'https://staging.employees.collums.co.uk',
    marketingUrl: process.env.REACT_APP_MARKETING_URL || 'https://staging.marketing.collums.co.uk',
    posUrl: process.env.REACT_APP_POS_URL || 'https://staging.pos.collums.co.uk',
    stockUrl: process.env.REACT_APP_STOCK_URL || 'https://staging.stock.collums.co.uk',
    reportingUrl: process.env.REACT_APP_REPORTING_URL || 'https://staging.reporting.collums.co.uk'
};
