export const viewScaffoldingStyles = (theme) => ({
    error: {
        textAlign: 'center',
        color: theme.palette.error.main,
        height: 17,
        fontSize: 12
    },
    actionButton: {
        width: '100%',
        height: 38,
        backgroundColor: theme.palette.wine.main,
        fontFamily: 'Roboto, Arial, Sans-serif',
        color: theme.palette.white.main,
        borderRadius: '1px',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.1rem',
        '&:hover': {
            backgroundColor: theme.palette.wine.hover,
            color: theme.palette.white.main
        },
        padding: '0.375rem 0.875rem'
    },
    description: {
        fontSize: 14,
        marginTop: '0.5rem',
        textAlign: 'center'
    },
    rightsText: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        whiteSpace: 'nowrap',
        fontSize: 14,
        textAlign: 'center'
    },
    logoIcon: {
        width: '125%',
        alignSelf: 'center'
    },
    logoText: { width: '100%', marginTop: '0.8rem', opacity: '0.8' },
    slogan: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: '0.725rem',
        letterSpacing: '0.21rem',
        whiteSpace: 'nowrap',
        marginTop: '0.1rem'
    }
});
