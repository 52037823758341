import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button, DialogActions, Typography} from '@material-ui/core';
import {modalsButtonStyles} from "../styles/buttonsStyles";

const styles = {
    dialogClass: {
        '& .MuiDialog-paper': {
            paddingBottom: 24
        }
    },
    content: {
        '& .MuiDialogContent-root': {
            paddingTop: 8,
            paddingBottom: 18
        }
    },
    closeButton: {
        position: 'absolute',
        right: 6,
        top: 6,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 20
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            fontSize: 16,
            fontWeight: 500
        }
    }
};

const Modal = ({ isOpen = false, onCancel, onConfirm, confirmLabel, title, children, classes }) => {
    const buttons = [];
    const Dialog = MuiDialog ;

    if (onConfirm) {
        buttons.push(
            <Button
                className={classes.confirmButton}
                key="confirm"
                onClick={onConfirm}
            >
                {confirmLabel || 'Confirm'}
            </Button>
        );
    }


        return (
            <Dialog
                fullWidth
                maxWidth={'sm'}
                height={"200px"}
                id="modal"
                open={isOpen}
                onClose={onCancel}
                classes={{ root: classes.dialogClass }}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography variant="h3">{title}</Typography>
                </DialogTitle>
                <div className={classes.content}>
                    <DialogContent>{children}</DialogContent>
                </div>
                {<DialogActions>{buttons}</DialogActions>}
            </Dialog>
        );
}

export default withStyles(theme => ({ ...styles, ...modalsButtonStyles(theme) }))(Modal);
