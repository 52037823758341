export const loginViewStyles = (theme) => ({
    textField: {
        width: '100%',
        '& input': {
            fontSize: 14
        }
    },
    input: {
        color: 'black',
        fontSize: 14,
        marginTop: '0.2rem'
    },
    link: {
        color: theme.palette.blue.main,
        marginTop: 4,
        fontSize: 14,
        '&:hover': {
            cursor: 'pointer'
        }
    }
});
