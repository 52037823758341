import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LoginView from './components/login/LoginView';
import ForgotPasswordView from './components/forgotPassword/ForgotPasswordView';
import ChangePasswordView from './components/changePassword/ChangePasswordView';

function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={LoginView} />
            <Route exact path="/login" component={LoginView} />
            <Route path="/forgot-password" component={ForgotPasswordView} />
            <Route path="/change-password/:code" component={ChangePasswordView} />
        </Switch>
    );
}

export default Routes;
