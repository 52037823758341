import React, { useState } from 'react';
import { Link, TextField, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import ViewScaffolding from '../common/ViewScaffolding';
import authApi from '../../api/authApi';
import { forgotPasswordViewStyles } from './styles';
import Modal from "../Modal";

const ForgotPasswordView = ({ classes, history }) => {
    const [form, setForm] = useState({ email: '' });
    const [errorMessage, setErrorMessage] = useState(null);
    const [resultMessage, setResultMessage] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const submit = async () => {
        //eslint-disable-next-line
        if (!form.email || !form.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) ) {
            setErrorMessage('Email is not valid');
            return false;
        } else {
            await submitConfirm().then((res) => {
                if(res) setOpenConfirm(true)
            })
        }
    };

    const submitConfirm = async () => {
        setSubmitted(true);
        setResultMessage(null);
        setErrorMessage(null);
        try {
            await authApi.resetPassword(form);
            return true
        } catch (error) {
            return true
        }
    };

    const toLogin = async() => history.push('/login')

    const onCLoseConfirm = () => setOpenConfirm(false);

    const change = (property, value) => {
        setForm({ ...form, [property]: value });
        setErrorMessage(null);
    };

    return (
        <>
            <Modal
                isOpen={openConfirm}
                className={classes.modalContainer}
                onClose={onCLoseConfirm}
                onConfirm={toLogin}
                confirmLabel={"Return to log in page"}
                title={"Reset password"}
            >
                <div>
                    <Typography variant={'h4'}>
                        If the email exists in our system, a reset password link will be sent to you.
                        <br />
                        If not in your inbox, please also check your junk folder.
                    </Typography>
                </div>
            </Modal>

            <ViewScaffolding
                form={
                    <form>
                        <TextField
                            classes={{ root: classes.textField }}
                            InputLabelProps={{ className: classes.input }}
                            label="Email"
                            value={form.email}
                            onChange={(event) => change('email', event.target.value)}
                            onKeyDown={(event) => 13 === event.keyCode && submit()}
                            helperText={!form.email && submitted && 'A value must be provided'}
                            error={!form.email && submitted}
                        />
                    </form>
                }
                link={
                    <Link onClick={() => history.push('/login')}>
                        <Typography className={classes.link}>Login</Typography>
                    </Link>
                }
                action={submit}
                label="Reset password"
                description="Enter your email address and click the button to receive a reset password link."
                resultMessage={resultMessage}
                errorMessage={errorMessage}
            />
        </>
    );
};

ForgotPasswordView.propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(forgotPasswordViewStyles)(ForgotPasswordView);
