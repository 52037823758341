export const changePasswordViewStyles = () => ({
    textField: {
        width: '100%'
    },
    input: {
        color: 'black',
        fontSize: '0.8rem',
        marginTop: '0.2rem'
    },
    link: {
        fontSize: '0.8rem',
        '&:hover': {
            cursor: 'pointer'
        }
    }
});
