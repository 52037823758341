const COLLUMS_DOMAIN = '.collums.co.uk';

const COOKIE_KEYS = {
    EXP_TIME: 'expTime',
    LOGOUT_ACTIVE: 'logoutActive',
    TOKEN: 'token'
};

const LOCAL_STORAGE_KEYS = {
    TOKEN_KEY: 'token',
    USER_KEY: 'user'
};

module.exports = {
    COLLUMS_DOMAIN,
    COOKIE_KEYS,
    LOCAL_STORAGE_KEYS
};
