import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Link, TextField, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import ViewScaffolding from '../common/ViewScaffolding';
import api from '../../api/api';
import authApi from '../../api/authApi';
import { getQueryParams, isValidURL } from '../../services/locationHelper';
import config from '../../config';
import { loginViewStyles } from './styles';
import { useCookies } from 'react-cookie';
import { COLLUMS_DOMAIN, COOKIE_KEYS, LOCAL_STORAGE_KEYS } from '../../services/storageKeys';

function LoginView({ classes }) {
    const [form, setForm] = useState({ email: '', password: '' });
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [cookies, setCookies, removeCookie] = useCookies(['token']);
    const history = useHistory();
    const location = useLocation();

    const redirect = React.useCallback(
        (token) => {
            let params = getQueryParams(location);
            let url;

            if (isValidURL(params.redirect) || (params.redirect && params.redirect.includes('http://localhost'))) {
                url = params.redirect;
            } else {
                const urlMap = {
                    calendar: config.calendarUrl,
                    admin: config.adminUrl,
                    employees: config.employeesUrl,
                    marketing: config.marketingUrl,
                    pos: config.posUrl,
                    stock: config.stockUrl,
                    reporting: config.reportingUrl,
                    journey: config.journeyUrl
                };
                url = urlMap[params.redirect] || urlMap.calendar;
            }
            delete params.redirect;

            url = new URL(url);
            const mergedParams = {
                ...params,
                token
            };
            const codifiedParams = new URLSearchParams(mergedParams).toString();
            window.location = `${url.origin}${url.pathname || ''}?${codifiedParams}`;
        },
        [location]
    );

    useEffect(() => {
        const doEffect = async () => {
            const params = getQueryParams(location);
            if (params.logout === 'true') {
                await authApi.forceLogout();
                removeCookie('token');
            } else {
                const token = cookies.token;
                if (!token) {
                    return;
                }
                api.config({ headers: { Authorization: token } });
                await authApi.getMe();
                redirect(token);
            }
        };

        doEffect();
        // eslint-disable-next-line
    }, [location, redirect]);

    const submit = async () => {
        setSubmitted(true);
        if (!form.email || !form.password) {
            return;
        }
        try {
            const environment = {};
            if (window.location.hostname !== 'localhost') {
                environment.path = '/';
                environment.domain = COLLUMS_DOMAIN;
            }
            const result = await authApi.login(form);
            api.config({ headers: { Authorization: result.token } });
            localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN_KEY, result.token);
            localStorage.setItem(LOCAL_STORAGE_KEYS.USER_KEY, JSON.stringify(result.user));
            setCookies(COOKIE_KEYS.TOKEN, result.token, environment);
            setCookies(COOKIE_KEYS.EXP_TIME, result.autoLogout?.expTime, environment);
            setCookies(COOKIE_KEYS.LOGOUT_ACTIVE, result.autoLogout?.logoutActive, environment);
            redirect(result.token);
        } catch (error) {
            if (error && 401 === error.status) {
                if ('Please check your email for verify!' === error.data.message) {
                    setErrorMessage('This email address is not verified. Check your email for verify!');
                } else {
                    setErrorMessage('Invalid email or password');
                }
            } else {
                setErrorMessage('Something went wrong');
            }
        }
    };

    const change = (property, value) => {
        setForm({ ...form, [property]: value });
        setErrorMessage(null);
    };

    return (
        <ViewScaffolding
            form={
                <form>
                    <TextField
                        id="email-input"
                        classes={{ root: classes.textField }}
                        InputLabelProps={{ className: classes.input }}
                        color="primary"
                        size="small"
                        label="Email"
                        value={form.email}
                        onChange={(event) => change('email', event.target.value)}
                        onKeyDown={(event) => 13 === event.keyCode && submit()}
                        helperText={!form.email && submitted && 'A value must be provided'}
                        error={!form.email && submitted}
                    />
                    <TextField
                        id="password-input"
                        size="small"
                        color="primary"
                        classes={{ root: classes.textField }}
                        InputLabelProps={{ className: classes.input }}
                        label="Password"
                        type="password"
                        value={form.password}
                        onChange={(event) => change('password', event.target.value)}
                        onKeyDown={(event) => 13 === event.keyCode && submit()}
                        helperText={!form.password && submitted && 'A value must be provided'}
                        error={!form.password && submitted}
                    />
                </form>
            }
            link={
                <Link onClick={() => history.push('/forgot-password')}>
                    <Typography className={classes.link}>Forgot password</Typography>
                </Link>
            }
            action={submit}
            label="Log in"
            errorMessage={errorMessage}
        />
    );
}

LoginView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(loginViewStyles)(LoginView);
