import api from './api';

const login = ({ email, password }) =>
    api.request({
        method: 'POST',
        url: '/login',
        data: { email: (email || '').toLowerCase(), password }
    });

const getMe = () =>
    api.request({
        method: 'GET',
        url: '/user/me'
    });

const resetPassword = ({ email }) =>
    api.request({
        method: 'POST',
        url: '/reset-password',
        data: { email: (email || '').toLowerCase(), redirect: 'collums' }
    });

const changePassword = ({ password, code }) =>
    api.request({
        method: 'POST',
        url: `/reset-password/${code}`,
        data: { password }
    });
const forceLogout = () =>
    api.request({
        method: 'GET',
        url: `/user/logout?token=${localStorage.getItem('token') || ''}`
    });

export default {
    login,
    getMe,
    resetPassword,
    changePassword,
    forceLogout
};
