export const forgotPasswordViewStyles = () => ({
    textField: {
        width: '100%',
        '& input': {
            fontSize: 14
        }
    },
    input: {
        color: 'black',
        fontSize: 14,
        marginTop: '0.2rem'
    },
    link: {
        marginTop: 4,
        fontSize: 14,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
});
