import querystring from 'querystring';

export const getQueryParams = (location) => querystring.parse(location.search.substring(1, location.search.length));

export const isValidURL = (str) => {
    if (!str) return;

    try {
        const url = new URL(str);
        return ['http:', 'https:'].includes(url.protocol) && url.origin;
    } catch (err) {
        return false;
    }
};
