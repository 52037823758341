import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link, TextField, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import ViewScaffolding from '../common/ViewScaffolding';
import authApi from '../../api/authApi';
import api from '../../api/api';
import config from '../../config';
import { changePasswordViewStyles } from './styles';

const ChangePasswordView = ({ classes, history }) => {
    const [form, setForm] = useState({ password: '', confirmPassword: '' });
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const { code } = useParams();

    const submit = async () => {
        setSubmitted(true);
        if (!form.password || !form.confirmPassword) {
            return;
        }
        if (form.password !== form.confirmPassword) {
            setErrorMessage("Passwords doesn't match");
            return;
        }
        try {
            const changePasswordResult = await authApi.changePassword({ password: form.password, code });
            const loginResult = await authApi.login({
                email: changePasswordResult.email.address,
                password: form.password
            });
            api.config({ headers: { Authorization: loginResult.token } });
            await authApi.getMe();
            window.location = `${config.calendarUrl}?token=${loginResult.token}`;
        } catch (error) {
            setErrorMessage('Something went wrong');
        }
    };

    const change = (property, value) => {
        setForm({ ...form, [property]: value });
        setErrorMessage(null);
    };

    return (
        <ViewScaffolding
            form={
                <form>
                    <TextField
                        id="password-input"
                        classes={{ root: classes.textField }}
                        InputLabelProps={{ className: classes.input }}
                        color="primary"
                        size="small"
                        type="password"
                        label="Password"
                        value={form.password}
                        onChange={(event) => change('password', event.target.value)}
                        onKeyDown={(event) => 13 === event.keyCode && submit()}
                        helperText={!form.password && submitted && 'A value must be provided'}
                        error={!form.password && submitted}
                    />
                    <TextField
                        id="confirm-password-input"
                        classes={{ root: classes.textField }}
                        InputLabelProps={{ className: classes.input }}
                        color="primary"
                        size="small"
                        type="password"
                        label="Confirm password"
                        value={form.confirmPassword}
                        onChange={(event) => change('confirmPassword', event.target.value)}
                        onKeyDown={(event) => 13 === event.keyCode && submit()}
                        helperText={!form.confirmPassword && submitted && 'A value must be provided'}
                        error={!form.confirmPassword && submitted}
                    />
                </form>
            }
            link={
                <Link onClick={() => history.push('/login')}>
                    <Typography className={classes.link}>Login</Typography>
                </Link>
            }
            action={submit}
            label="Change password"
            description="Set new password"
            errorMessage={errorMessage}
        />
    );
};

ChangePasswordView.propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(changePasswordViewStyles)(ChangePasswordView);
